


























































































import Vue from "vue";
import HelloWorld from "./components/HelloWorld.vue";
import VuetifyAudio from "@/components/VuetifyAudio.vue";

import colors from "vuetify/lib/util/colors";

export default Vue.extend({
  name: "App",

  components: {
    VuetifyAudio,
    HelloWorld,
  },

  data: () => ({
    sheet: false,
    playerVolume: 0.8,
    isMuted: false,
    files: [
      { path: "/audio/hi-there.mp3", title: "Hi there!" },
      { path: "/audio/bigbird.mp3", title: "It's big bird!" },
      { path: "/audio/iammarty.mp3", title: "I am M4arty" },
      { path: "/audio/police.mp3", title: "Police" },
      { path: "/audio/no.mp3", title: "No" },
      { path: "/audio/that-was-beautiful.mp3", title: "That was beautiful" },
      { path: "/audio/hold-up.mp3", title: "Hold up!" },
      { path: "/audio/hahaha.mp3", title: "Hahaha!" },
      { path: "/audio/evil-laugh.mp3", title: "Evil laugh" },
      { path: "/audio/bear-ahhhh.mp3", title: "Bear AHHHH!" },
      {
        path: "/audio/let-me-put-on-some-pants.mp3",
        title: "Let me put on some pants",
      },
      { path: "/audio/always-rush.mp3", title: "Always rush" },
      { path: "/audio/ararrraaaghuahuahua.mp3", title: "ararrraaaghuahuahua" },
      { path: "/audio/defeated.mp3", title: "When Marty is defeated" },
      { path: "/audio/fimpto.mp3", title: "<3 Impto" },
      {
        path: "/audio/i-dont-have-patience.mp3",
        title: "I don't have the patience",
      },
      {
        path: "/audio/ive-been-keeping-a-secret.mp3",
        title: "I've been keeping a secret",
      },
      {
        path: "/audio/losing-facereveal.mp3",
        title: "Losing a face reveal",
      },
      {
        path: "/audio/hit-that-thing.mp3",
        title: "Can I hit that thing?",
      },
      {
        path: "/audio/diamond-pants.mp3",
        title: "Diamond pants!",
      },
      {
        path: "/audio/hello-bus.mp3",
        title: "Hello Bus!",
      },
      {
        path: "/audio/huu.mp3",
        title: "Huuu",
      },
      {
        path: "/audio/i-see-my-shit.mp3",
        title: "I see my shit!",
      },
      {
        path: "/audio/its-so-nice.mp3",
        title: "It's so nice!",
      },
      {
        path: "/audio/long-schlong.mp3",
        title: "Long schlong",
      },
      {
        path: "/audio/oh-food.mp3",
        title: "Oh food!",
      },
      {
        path: "/audio/its-grandmas-potpie.mp3",
        title: "It's grandma's pot pie!",
      },
      {
        path: "/audio/check-my-dms.mp3",
        title: "Check my DMs?",
      },
      {
        path: "/audio/argggh.mp3",
        title: "Argggh!!!",
      },
      {
        path: "/audio/shit.mp3",
        title: "SH*T", // rayyat
      },
    ],
  }),

  methods: {
    toggleMute() {
      this.isMuted = !this.isMuted;
    },
    getRandomColor() {
      let keys = Object.keys(colors);
      return keys[(keys.length * Math.random()) << 0];
    },
  },
});
