


























































import Vue from "vue";

const formatTime = (second: number) =>
  new Date(second * 1000).toISOString().substr(17, 2);

interface MartyFile {
  path: string;
  title: string;
}

export default Vue.extend({
  name: "vuetify-audio",

  props: {
    file: {
      type: Object as () => MartyFile,
      default: null,
    },
    ended: {
      type: Function,
      default: () => {
        console.log("ended");
      },
    },
    color: {
      type: String,
      default: null,
    },
    volume: {
      type: Number,
      default: 0.8,
    },
    muted: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      firstPlay: true,
      loaded: false,
      error: false,
      playing: false,
      paused: false,
      percentage: 0,
      currentTime: "00",
      audio: undefined as unknown as HTMLAudioElement,
      totalDuration: 0,
    };
  },

  computed: {
    duration(): string {
      return this.audio ? formatTime(this.totalDuration) : "";
    },
  },

  watch: {
    muted() {
      this.audio.muted = this.muted;
    },
  },

  methods: {
    setPosition() {
      this.audio.currentTime = (this.audio.duration / 100) * this.percentage;
    },
    stop() {
      this.audio.pause();
      this.paused = true;
      this.playing = false;
      this.audio.currentTime = 0;
    },
    play() {
      if (this.playing) return;
      this.audio
        .play()
        .then(() => (this.playing = true))
        .catch((e) => {
          console.error(e);
          this.error = true;
          this.stop();
        });
      this.paused = false;
    },
    pause() {
      this.paused = !this.paused;
      this.paused ? this.audio.pause() : this.audio.play();
    },
    download() {
      this.audio.pause();
      window.open(this.file?.path, "download");
    },
    reload() {
      this.audio.load();
    },
    canPlay() {
      this.error = false;
      console.log("can play");
    },
    _handleLoaded: function () {
      if (this.audio.readyState >= 1) {
        if (this.audio.duration === Infinity) {
          // Fix duration for streamed audio source or blob based
          // https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome/39971175#39971175
          /*
          this.audio.currentTime = 1e101;
          this.audio.ontimeupdate = () => {
            this.audio.ontimeupdate = () => {
              console.log("nothing");
            };
            this.audio.currentTime = 0;
            this.totalDuration = this.audio.duration;
          };
           */
          this.loaded = true;
        } else {
          this.totalDuration = this.audio.duration;
          this.loaded = true;
        }
      } else {
        alert(`Failed to load sound file ${this.file.path}`);
        console.error(`Failed to load sound file ${this.file.path}`);
      }
    },
    _handlePlayingUI: function (e: Event) {
      this.audio.volume = this.volume;
      if (this.audio.duration) {
        this.percentage = (this.audio.currentTime / this.audio.duration) * 100;
      }
      this.currentTime = formatTime(this.audio.currentTime);
      this.playing = true;
    },
    _handlePlayPause: function (e: Event) {
      if (e.type === "play" && this.firstPlay) {
        // in some situations, audio.currentTime is the end one on chrome
        this.audio.currentTime = 0;
        if (this.firstPlay) {
          this.firstPlay = false;
        }
      }
      if (e.type === "pause" && !this.paused && !this.playing) {
        this.currentTime = "00";
      }
    },
    _handleEnded() {
      this.paused = false;
      this.playing = false;
    },
    init: function () {
      this.audio.addEventListener("timeupdate", this._handlePlayingUI);
      this.audio.addEventListener("loadeddata", this._handleLoaded);
      this.audio.addEventListener("pause", this._handlePlayPause);
      this.audio.addEventListener("play", this._handlePlayPause);
      this.audio.addEventListener("ended", this._handleEnded);
    },
  },

  mounted() {
    this.audio = this.$refs.player as HTMLAudioElement;
    this.init();
  },

  beforeDestroy() {
    this.audio.removeEventListener("timeupdate", this._handlePlayingUI);
    this.audio.removeEventListener("loadeddata", this._handleLoaded);
    this.audio.removeEventListener("pause", this._handlePlayPause);
    this.audio.removeEventListener("play", this._handlePlayPause);
    this.audio.removeEventListener("ended", this._handleEnded);
  },
});
